export default {
  'app.title': 'Data Ingestion Engine',
  'app.login.title': 'Login to Data Ingestion Engine',
  'app.login.email.input.label': 'Email',
  'app.login.password.input.label': 'Password',
  'app.login.button.text': 'Login',
  'app.login.link.text': 'Back To Login',
  'app.reset.password.title': 'Reset Your Password',
  'app.reset.password.button.text': 'Email Me Reset Link',
  'app.reset.password.link.text': 'Reset Password',
  'app.reset.password.description':
    "Enter your email and we'll send you a link to reset your password",
  'app.reset.password.description.fromemail':
    'Enter a new password for your account',
  'app.common.action.button': 'Action(s)',
  'app.common.action.button.save': 'Save',
  'app.common.action.button.cancel': 'Cancel',
  'app.change.password.title': 'Change Your Password',
  'app.change.password.old.input.label': 'Old Password',
  'app.change.password.new.input.label': 'New Password',
  'app.change.password.confirm.input.label': 'Confirm Password',

  'nav.dashboard': 'Dashboard',
  'nav.data.source.profile': 'Data Source Profile',
  'nav.data.source.profile.view': 'Data Profile Detail',
  'nav.products': 'Products',
  'nav.product.detail': 'Product Detail',
  'nav.data.import.new.upload': 'Data Import',
  'nav.data.import.history': 'Import Data History',
  'nav.channels': 'Channel',
  'nav.channel': 'Channel',
  'nav.users': 'Users & Permissions',
  'nav.users.add': 'Add a user',
  'nav.users.edit': 'Edit user info',
  'nav.settings': 'Settings',
  'nav.logout': 'Logout',
  'nav.change.password': 'Change Password',

  'app.form.validate.email.required': 'Email is a required field',
  'app.form.validate.email.invalid': 'Please enter a valid email',
  'app.form.validate.password.required': 'Password is a required field',
  'app.form.validate.password.min': 'Password must be at least 6 characters',
  'app.form.validate.password.max': 'Password must be at last 16 characters',
  'app.form.validate.password.not.match': 'Passwords must match',
  'app.forgot.password.email.sent.success':
    'Email has been sent successfully, please check it.',
  'app.change.password.success':
    'Password has been changed successfully, please log in again',
  'app.reset.password.success':
    'Password has been reset successfully, please log in again',

  'page.dashboard.welcome': 'Welcome',
  'page.dashboard.login.with': 'You are login with',
  'page.data.source.profile.search.placeholder': 'Search Profile Name',
  'page.data.source.profile.table.id': 'ID',
  'page.data.source.profile.table.updated': 'Last Updated',
  'page.data.source.profile.button.add.new': 'Add New Profile',
  'page.data.source.profile.delete.profile.confirm':
    'Confirm to delete profile:',
  'page.data.source.profile.page.title': 'New Profile',
  'page.data.source.profile.form.validate.profile.name.required':
    'Profile Name is a required field',
  'page.data.source.profile.form.validate.source.name.required':
    'Source Name is a required field',
  'page.data.source.profile.button.view': 'View',
  'page.data.source.profile.button.delete': 'Delete',
  'page.data.source.profile.button.back': 'Back',
  'page.data.source.profile.button.upload': 'Upload',
  'page.data.source.profile.button.cancel': 'Cancel',
  'page.data.source.profile.button.finish': 'Finish',
  'page.data.source.profile.button.next': 'Next',
  'page.data.source.profile.button.download': 'Download',
  'page.data.source.profile.basic.info.title': 'General Information',
  'page.data.source.profile.basic.info.ext': 'Target Channel',
  'page.data.source.profile.basic.info.channel': 'Magento',
  'page.data.source.profile.basic.info.profile.name': 'Profile Name',
  'page.data.source.profile.basic.info.source.name': 'Source Name',
  'page.data.source.profile.basic.info.source.type': 'Source Type',
  'page.data.source.profile.source.file.upload.tooltip':
    'Automatically match the attribute if source attribute label matches the selected target channel field',
  'page.data.source.profile.field.mapping.dropdown.label':
    'Auto Attribute Mapping Field',
  'page.data.source.profile.filetype.label': 'Source File Type',
  'page.data.source.profile.template.download.link.text':
    'Download File Template',
  'page.data.source.profile.text.note': 'note',
  'page.data.source.profile.text.note.content':
    'Attribute SKU is required, SKU will be the product primary key',
  'page.data.source.profile.file.analysis.attribute.number':
    'Number of Attributes',
  'page.data.source.profile.file.analysis.attribute.mapping':
    'Target Auto Attribute Mapping Field',
  'page.data.source.profile.file.analysis.matched.attribute':
    'Number of Successfully matched Attributes',
  'page.data.source.profile.file.analysis.note.title': 'Important Note',
  'page.data.source.profile.file.analysis.note.first.half': 'There are ',
  'page.data.source.profile.file.analysis.note.second.half':
    " attributes requires manual mapping. If you believe you don't have all these attributes created in the target channel, please ",
  'page.data.source.profile.file.analysis.download.tips':
    'click the button below',
  'page.data.source.profile.file.analysis.note.last.half':
    ' to download our attribute creation suggestion based on the source file data before you start manual mapping for unmatched attributes.',
  'page.data.source.profile.attribute.mapping.source.label': 'Source Label',
  'page.data.source.profile.attribute.mapping.title.source': 'Source',
  'page.data.source.profile.attribute.mapping.title.target': 'Target',
  'page.data.source.profile.preview.mapping.number':
    'Number of Attribute Mapping',
  'page.data.source.profile.attribute.mapping.attribute.label':
    'Attribute Label',
  'page.data.source.profile.attribute.mapping.attribute.code': 'Attribute Code',
  'page.data.source.profile.attribute.mapping.attribute.type': 'Attribute Type',
  'page.data.source.profile.attribute.mapping.attribute.sets': 'Attribute Sets',
  'page.data.source.profile.attribute.mapping.filterable.in.category':
    'filterable in Category',
  'page.data.source.profile.attribute.mapping.filterable.in.search':
    'filterable in Search',
  'page.data.source.profile.attribute.mapping.searchable': 'Searchable',
  'page.data.source.profile.attribute.mapping.visible.in.frontend':
    'Visible in Frontend',
  'page.data.source.profile.attribute.mapping.dialog.title':
    'Finished Attribute Mapping?',
  'page.data.source.profile.attribute.mapping.dialog.text':
    "You haven't assign target fields for some source file attributes.Are you sure you want to skip these attributes for this profile?",
  'page.data.source.profile.steps.title': [
    'Basic Info',
    'Source File Upload',
    'File Analysis',
    'Attribute Mapping',
    'Profile Preview'
  ],
  'page.data.source.profile.steps.title.general.information':
    'General Information',
  'page.data.source.profile.button.confirm': 'Confirm',

  'page.new.data.upload.select.label': 'Please Select a Profile',
  'page.new.data.upload.note':
    'Display Profile Details here after the drowdown selection ',
  'page.new.data.upload.link': 'Add New Profile',
  'page.new.data.upload.button.text': 'process file',

  'page.users.add.new': 'Add New',
  'page.users.list.table.title.email': 'Email',
  'page.users.list.table.title.role': 'User Role(s)',
  'page.users.list.table.title.status': 'Status',
  'page.users.list.action.button.edit': 'Edit',
  'page.users.list.action.button.delete': 'delete',
  'page.users.list.search.placeholder': 'search users…',
  'page.users.list.delete.user.confirm': 'Confirm to delete user:',
  'page.users.list.status': ['inactive', 'active'],
  'page.user.info.form.email.label': 'Email',
  'page.user.info.form.status.label': 'Status',
  'page.user.info.form.user.role.label': 'User Role(s)',
  'page.user.info.form.permissions.label': 'Permissions',
  'page.user.info.form.email.required': 'Email is a required field',
  'page.user.info.form.email.invalid': 'Please enter a valid email',
  'page.user.info.form.roles.required': 'Role(s) is a required field',
  'page.user.info.form.user.update.success': 'User updated successfully',
  'page.user.info.form.user.create.success': 'User created successfully',
  'page.user.info.form.user.delete.success': 'User deleted successfully',

  'page.file.upload.list.table.title.id': 'Id',
  'page.file.upload.list.table.title.file.name': 'File Name',
  'page.file.upload.list.table.title.status': 'Status',
  'page.file.upload.list.table.title.created': 'Created',
  'page.file.upload.list.table.title.last.attempt': 'Last Attempt',
  'page.file.upload.list.table.title.attempts': 'Attempts',
  'page.file.upload.list.action.button.download': 'Download',
  'page.file.upload.list.table.title.error.report': 'Error Report',
  'page.file.upload.list.status': ['Failed', 'Processing', 'Synchronized'],
  'page.users.list.table.title.id': 'ID',
  'page.users.list.table.title.sku': 'SKU',
  'page.users.list.table.title.created': 'Created',
  'page.users.list.table.title.updated': 'Updated',
  'page.users.list.table.title.LastUpdatedbyFile': 'Last Updated by File',
  'page.product.list.table.title.id': 'ID',
  'page.product.list.search.placeholder': 'Search SKU',
  'page.product.list.table.title.sku': 'SKU',
  'page.product.list.table.title.created': 'Created',
  'page.product.list.table.title.updated': 'Updated',
  'page.product.list.table.title.lastupdatedbyfile': 'Last Updated by File',

  'page.channels.no.channel.id': 'No channel info'
}
