import * as users from './users'
import * as profile from './profile'
import * as logs from './logs'
import * as product from './product'
import * as files from './files'
import * as channels from './channels'

export default {
  users,
  profile,
  logs,
  product,
  files,
  channels
}
